import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/affiliates.js'), 'affiliates.js')
  resolveStoreModules(require('../store/agents.js'), 'agents.js')
  resolveStoreModules(require('../store/announcements.js'), 'announcements.js')
  resolveStoreModules(require('../store/auth.js'), 'auth.js')
  resolveStoreModules(require('../store/bank-accounts.js'), 'bank-accounts.js')
  resolveStoreModules(require('../store/banners.js'), 'banners.js')
  resolveStoreModules(require('../store/bets.js'), 'bets.js')
  resolveStoreModules(require('../store/cmses.js'), 'cmses.js')
  resolveStoreModules(require('../store/currencies.js'), 'currencies.js')
  resolveStoreModules(require('../store/custom-domain.js'), 'custom-domain.js')
  resolveStoreModules(require('../store/dashboard.js'), 'dashboard.js')
  resolveStoreModules(require('../store/dialog.js'), 'dialog.js')
  resolveStoreModules(require('../store/external-data.js'), 'external-data.js')
  resolveStoreModules(require('../store/finance-crypto.js'), 'finance-crypto.js')
  resolveStoreModules(require('../store/finance.js'), 'finance.js')
  resolveStoreModules(require('../store/frontend.js'), 'frontend.js')
  resolveStoreModules(require('../store/games.js'), 'games.js')
  resolveStoreModules(require('../store/invite-and-earn.js'), 'invite-and-earn.js')
  resolveStoreModules(require('../store/language-strings.js'), 'language-strings.js')
  resolveStoreModules(require('../store/lwl-reports.js'), 'lwl-reports.js')
  resolveStoreModules(require('../store/manual.js'), 'manual.js')
  resolveStoreModules(require('../store/master.js'), 'master.js')
  resolveStoreModules(require('../store/operator.js'), 'operator.js')
  resolveStoreModules(require('../store/operatorSearch.js'), 'operatorSearch.js')
  resolveStoreModules(require('../store/permissions.js'), 'permissions.js')
  resolveStoreModules(require('../store/player-crypto.js'), 'player-crypto.js')
  resolveStoreModules(require('../store/player-reward.js'), 'player-reward.js')
  resolveStoreModules(require('../store/players.js'), 'players.js')
  resolveStoreModules(require('../store/promotion-code.js'), 'promotion-code.js')
  resolveStoreModules(require('../store/promotion.js'), 'promotion.js')
  resolveStoreModules(require('../store/referral-rate.js'), 'referral-rate.js')
  resolveStoreModules(require('../store/search.js'), 'search.js')
  resolveStoreModules(require('../store/setting-tab.js'), 'setting-tab.js')
  resolveStoreModules(require('../store/settings.js'), 'settings.js')
  resolveStoreModules(require('../store/sockets.js'), 'sockets.js')
  resolveStoreModules(require('../store/stats.js'), 'stats.js')
  resolveStoreModules(require('../store/users.js'), 'users.js')
  resolveStoreModules(require('../store/report/index.js'), 'report/index.js')
  resolveStoreModules(require('../store/affiliate/auth.js'), 'affiliate/auth.js')
  resolveStoreModules(require('../store/affiliate/banks.js'), 'affiliate/banks.js')
  resolveStoreModules(require('../store/affiliate/frontend.js'), 'affiliate/frontend.js')
  resolveStoreModules(require('../store/affiliate/players.js'), 'affiliate/players.js')
  resolveStoreModules(require('../store/agent/auth.js'), 'agent/auth.js')
  resolveStoreModules(require('../store/agent/banks.js'), 'agent/banks.js')
  resolveStoreModules(require('../store/agent/frontend.js'), 'agent/frontend.js')
  resolveStoreModules(require('../store/agent/players.js'), 'agent/players.js')
  resolveStoreModules(require('../store/cache/bets.js'), 'cache/bets.js')
  resolveStoreModules(require('../store/cache/dashboard.js'), 'cache/dashboard.js')
  resolveStoreModules(require('../store/cache/deposit.js'), 'cache/deposit.js')
  resolveStoreModules(require('../store/cache/manual-adjustment.js'), 'cache/manual-adjustment.js')
  resolveStoreModules(require('../store/cache/players.js'), 'cache/players.js')
  resolveStoreModules(require('../store/cache/stats.js'), 'cache/stats.js')
  resolveStoreModules(require('../store/report/overall.js'), 'report/overall.js')
  resolveStoreModules(require('../store/website-setting/ip-whitelist.js'), 'website-setting/ip-whitelist.js')
  resolveStoreModules(require('../store/website-setting/language-string.js'), 'website-setting/language-string.js')
  resolveStoreModules(require('../store/website-setting/languages.js'), 'website-setting/languages.js')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
